import { useCallback, useState } from 'react'
import { NavigationSlider, Button, Icon } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'

import Activity from './Activity'
import { interactionModelOperations } from '../../redux'
import { getActivityFromActivityModel } from '../../../../utils/interactionModel'
import { getStepErrorMessages, showError } from '../../utils/validation'
import { deepClone } from '../../../../utils/helpers/object'
import { ActivityGroup as ActivityGroupType, ActivityModel } from 'types/shared'
import { useAppDispatch, useAppSelector } from 'state/store'
import useValidateStep from 'modules/interaction/hooks/useValidateStep/useValidateStep'
import { flatValuesToOldActivities } from 'modules/interaction/utils/flatValuesToOldActivities'

export type ActivityGroupProps = {
  currentActivityModel: ActivityGroupType
  onChange: (activies: string) => void
  label: string
  currentStep: number
}

const ActivityGroup = ({
  currentActivityModel,
  onChange,
  label,
  currentStep
}: ActivityGroupProps) => {
  const totalElements = currentActivityModel.activity_models.length
  const [currentPosition, setCurrentPosition] = useState(totalElements || 1)

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { interactionModel } = useAppSelector(
    ({ interactions }) => interactions
  )

  const addIndexesToUuids = (
    activityModels: ActivityModel[][],
    nextIndex: number
  ) => {
    const firstActivityModel = deepClone(activityModels[0])
    const newActivityModel = firstActivityModel.map((activity) => {
      delete activity.value
      return {
        ...activity,
        uuid: activity.originalUuid + '-' + nextIndex
      }
    })
    return newActivityModel
  }

  const { validateStep } = useValidateStep()

  const handleAddGroup = async () => {
    const flatData = interactionModel.flatData

    if (!flatData) {
      return
    }

    const currentStepData =
      flatData.interaction_model_item_groups[currentStep - 1]
    const activityModels = flatData.activity_models
    const activityValues = flatData.activity_values

    if (!currentStepData) {
      return
    }

    const { invalidActivities, updatedActivityValues } = await validateStep({
      stepActivities: currentStepData.activity_models,
      activityModels,
      activityValues
    })

    if (invalidActivities.length) {
      dispatch(
        interactionModelOperations.updateActivities(
          flatValuesToOldActivities(updatedActivityValues)
        )
      )

      getStepErrorMessages({
        invalidActivities,
        stepName: currentStepData.des,
        t
      }).forEach((error) => showError(error))

      return
    }

    const newActivityGroup = addIndexesToUuids(
      currentActivityModel.activity_models,
      totalElements
    )
    dispatch(
      interactionModelOperations.addActivityGroup(
        currentActivityModel.uuid,
        newActivityGroup
      )
    )
    setCurrentPosition(totalElements + 1)
  }

  const handleRemoveGroup = (removedGroupIndex: number) => {
    dispatch(
      interactionModelOperations.removeActivityGroup(
        currentActivityModel.uuid,
        removedGroupIndex
      )
    )
    if (currentPosition - 1 > 0) {
      setCurrentPosition(currentPosition - 1)
    }
  }

  const changeActivity = useCallback(
    (uuid: string, value: ActivityModel['value']) => {
      const updatedActivityModel = deepClone(currentActivityModel)

      updatedActivityModel.activity_models.forEach((group) =>
        group.map((activityModel) => {
          if (activityModel.uuid === uuid) {
            activityModel.value = value
          }
          return activityModel
        })
      )

      const groups = updatedActivityModel.activity_models

      const groupsActivities = groups.map((activityModels) => {
        const activities = activityModels.map(getActivityFromActivityModel)
        const nonEmptyActivities = activities.filter(
          (activity) => activity.uuid_activity_model === uuid || activity.des
        )
        return nonEmptyActivities
      })

      onChange(JSON.stringify(groupsActivities))
    },
    [currentActivityModel, onChange]
  )

  const currentActivityModels =
    currentActivityModel.activity_models[currentPosition - 1]

  return (
    <>
      <NavigationSlider
        currentPosition={currentPosition}
        first={1}
        total={totalElements}
        hierarchyLevel='level_3'
        doubleArrowhead={true}
        textForCurrent='Elemento'
        afterClick={setCurrentPosition}
        heightClass='xSmall'
      />
      <div>
        <div className='d-flex justify-content-between mt-2'>
          <h2>{label}</h2>
          <div className='d-flex'>
            <Button
              impactClass='navigation'
              onClick={handleAddGroup}
              heightClass='small'
              style={{
                width: '34px',
                marginLeft: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Icon iconName='Plus' icSize='small' />
            </Button>
            <Button
              impactClass='navigation'
              variant='secondary'
              onClick={() => handleRemoveGroup(currentPosition - 1)}
              heightClass='small'
              disabled={totalElements === 1}
              style={{
                width: '34px',
                marginLeft: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Icon iconName='Cleaner' icSize='small' />
            </Button>
          </div>
        </div>
      </div>

      <div>
        {currentActivityModels &&
          currentActivityModels.map((activity) => (
            <Activity
              key={activity.uuid}
              activityModelInput={activity}
              changeActivity={changeActivity}
              currentIndexActivityGroup={currentPosition - 1}
              currentStep={currentStep}
            />
          ))}
      </div>
    </>
  )
}

export default ActivityGroup
