import { DocumentListCard } from '@yes.technology/react-toolkit'

import Activity from './Activity'
import EditStep from './EditStep/EditStep'
import styled from 'styled-components'
import { InteractionModelItemGroup } from 'types/shared'

interface ItemProps {
  children: React.ReactNode
}

export const Item = styled.div<ItemProps>`
  position: relative;

  h2 {
    width: calc(100% - 32px);
  }
`

interface ItemGroupProps {
  itemGroups: InteractionModelItemGroup[]
  goToStep: (step: number) => void
}

const ItemGroup = ({ itemGroups, goToStep }: ItemGroupProps) => {
  return (
    <>
      {itemGroups &&
        itemGroups.map((itemGroup, i) => {
          const step = i + 1

          const activityModels = itemGroup.activity_models.filter(
            (activityModel) => activityModel.content_type !== 'safe_html'
          )

          return activityModels.length ? (
            <Item key={itemGroup.uuid}>
              <DocumentListCard className='mt-2'>
                <EditStep onClick={() => goToStep(step)} />
                <h2>{itemGroup.des}</h2>
                <div className='card-body'>
                  {itemGroup.complement && <p>{itemGroup.complement}</p>}

                  <Activity activityModels={activityModels} />
                </div>
              </DocumentListCard>
            </Item>
          ) : null
        })}
    </>
  )
}

export default ItemGroup
