import { InteractionModel } from 'types/shared'
import { getStepsToBeShown } from '../utils/validation'
import { useMemo, useRef, useState } from 'react'
import useValidateStep from './useValidateStep/useValidateStep'
import debounce from 'lodash.debounce'

const useValidationInput = (interactionModel: InteractionModel) => {
  const steps = interactionModel?.data?.interaction_model_item_groups
  const flatSteps = interactionModel?.flatData?.interaction_model_item_groups

  const shownStepsActivities = useMemo(() => {
    if (!flatSteps) {
      return []
    }

    const shownStepsUuids = getStepsToBeShown(steps).map(({ uuid }) => uuid)

    const allShownActivities = flatSteps.flatMap((flatStep) =>
      shownStepsUuids.includes(flatStep.uuid) ? flatStep.activity_models : []
    )

    return allShownActivities
  }, [flatSteps, steps])

  const activityModels = useMemo(
    () => interactionModel?.flatData?.activity_models || {},
    [interactionModel?.flatData?.activity_models]
  )
  const activityValues = useMemo(
    () => interactionModel?.flatData?.activity_values || {},
    [interactionModel?.flatData?.activity_values]
  )

  const lastActivityValues = useRef<typeof activityValues>()

  const shouldValidate =
    !lastActivityValues.current ||
    JSON.stringify(lastActivityValues.current) !==
      JSON.stringify(activityValues)
  lastActivityValues.current = activityValues

  return {
    stepActivities: shownStepsActivities,
    activityModels,
    activityValues,
    shouldValidate
  }
}

const DEFAULT_VALIDATION_DEBOUNCE_MS = 500

const useNavigation = (
  interactionModel: InteractionModel,
  debounceMs = DEFAULT_VALIDATION_DEBOUNCE_MS
) => {
  const { activityModels, activityValues, stepActivities, shouldValidate } =
    useValidationInput(interactionModel)

  const { validateStep } = useValidateStep()

  const lastValidationPromise = useRef<ReturnType<typeof validateStep>>()

  const [validatedInteraction, setValidatedInteraction] = useState(false)

  const validateDebounced = debounce(() => {
    const currentValidationPromise = validateStep({
      stepActivities,
      activityModels,
      activityValues
    })

    lastValidationPromise.current = currentValidationPromise

    currentValidationPromise.then(({ invalidActivities }) => {
      if (lastValidationPromise.current !== currentValidationPromise) {
        return
      }

      setValidatedInteraction(invalidActivities.length === 0)
    })
  }, debounceMs)

  const cancelLastDebouncedValidation = useRef(() => {})

  if (shouldValidate) {
    setValidatedInteraction(false)

    cancelLastDebouncedValidation.current()

    validateDebounced()
    cancelLastDebouncedValidation.current = () => validateDebounced.cancel()
  }

  return {
    showDoubleArrowhead: validatedInteraction
  }
}

export default useNavigation
