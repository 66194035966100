import {
  sendMessageToParent,
  useDisplayUserError
} from '@yes.technology/react-toolkit'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18n-lite'

import {
  FlatInteractionModelItemGroup,
  InteractionModel,
  InteractionModelData,
  InteractionModelItemGroup
} from 'types/shared'
import {
  saveInteraction,
  validateInteractionModelCallback,
  validateInteractionModelItemGroupCallback
} from '../../../utils/interactionModel'
import { interactionModelOperations } from '../redux'
import {
  getStepErrorMessages,
  getStepsToBeShown,
  showError
} from '../utils/validation'
import useAutomation from './useAutomation'
import { useAppDispatch } from 'state/store'
import { fetchWorkflow } from './useWorkflow'
import useValidateStep from './useValidateStep/useValidateStep'
import { flatValuesToOldActivities } from '../utils/flatValuesToOldActivities'

const DEFAULT_AUTOMATION_DELAY = 6000

const useStep = (interactionModel: InteractionModel, onSave: () => void) => {
  const { t, language } = useTranslation()

  const dispatch = useAppDispatch()

  const [currentStep, setCurrentStep] = useState(1)
  const [uuidInteraction, setUuidInteraction] = useState(null)
  const [isLoadedSteps, setIsLoadedSteps] = useState(false)
  const [isInteractionFinished, setIsInteractionFinished] = useState(false)

  const steps: InteractionModelItemGroup[] = useMemo(() => {
    const steps = getStepsToBeShown(
      interactionModel?.data?.interaction_model_item_groups || []
    )
    return JSON.parse(JSON.stringify(steps))
  }, [interactionModel])

  const flatSteps: FlatInteractionModelItemGroup[] = useMemo(() => {
    const shownStepIds = steps.map(({ uuid }) => uuid)

    return (
      interactionModel.flatData?.interaction_model_item_groups.filter(
        ({ uuid }) => shownStepIds.includes(uuid)
      ) || []
    )
  }, [interactionModel.flatData?.interaction_model_item_groups, steps])

  const flatActivityModels = useMemo(
    () => interactionModel.flatData?.activity_models || {},
    [interactionModel.flatData?.activity_models]
  )

  const flatActivityValues = useMemo(
    () => interactionModel.flatData?.activity_values || {},
    [interactionModel.flatData?.activity_values]
  )

  const totalSteps = steps.length + 1
  const currentStepData = steps.length ? steps[currentStep - 1] : undefined

  const initialStepUuid = interactionModel.data?.initial_step_uuid
  const initialStepIndex =
    steps.findIndex((step) => step.uuid === initialStepUuid) + 1

  useEffect(() => {
    setCurrentStep(initialStepIndex || 1)
  }, [initialStepIndex])

  useEffect(() => {
    if (totalSteps === 1) return
    setIsLoadedSteps(true)
  }, [totalSteps])

  useEffect(() => {
    if (
      isInteractionFinished &&
      !interactionModel?.isFetching &&
      uuidInteraction
    ) {
      const informationCards = interactionModel?.data?.information_cards
      const hasConclusionCard = informationCards?.some(
        (card) => card.display_on === 'interaction-finished'
      )

      if (!hasConclusionCard) {
        sendMessageToParent(
          interactionModel.workflowUuid || '',
          uuidInteraction
        )
      }
    }
  }, [isInteractionFinished, interactionModel, uuidInteraction])

  const executeAutomation = useAutomation()

  const executeAutomationsAfterSuccess = async () => {
    const automationDelay =
      interactionModel?.flatData?.automation_delay || DEFAULT_AUTOMATION_DELAY
    await new Promise((r) => setTimeout(r, automationDelay))

    const automations = interactionModel?.data?.automations || []

    const sortedAutomations = automations
      .slice()
      .sort((firstAutomation, secondAutomation) => {
        if (firstAutomation.automation_type === 'redirect') return 1
        if (secondAutomation.automation_type === 'redirect') return -1
        return 0
      })

    const workflow = await fetchWorkflow(interactionModel.workflowUuid).then(
      (response) => response.data?.workflow
    )

    for (const automation of sortedAutomations) {
      if (automation.trigger === 'submit-success') {
        await executeAutomation(automation, workflow)
      }
    }
  }

  const { displayUserError } = useDisplayUserError()

  const { validateStep } = useValidateStep()

  const saveAndFinish = async (data: InteractionModelData) => {
    const stepValidations = await Promise.all(
      flatSteps.map(async (flatStep) => {
        const { invalidActivities } = await validateStep({
          activityModels: flatActivityModels,
          activityValues: flatActivityValues,
          stepActivities: flatStep.activity_models
        })

        return {
          invalidActivities,
          stepName: flatStep.des
        }
      })
    )

    stepValidations.forEach(({ invalidActivities, stepName }) =>
      getStepErrorMessages({
        invalidActivities,
        stepName,
        t
      }).forEach((error) => showError(error))
    )

    if (
      stepValidations.some(
        ({ invalidActivities }) => invalidActivities.length > 0
      )
    ) {
      return false
    }

    if (data.validation_callback) {
      const responseValidationCallback = await validateInteractionModelCallback(
        data,
        data.validation_callback
      )
      if (!responseValidationCallback?.success) {
        displayUserError({ error: responseValidationCallback })
        return false
      }
    }

    const response = await saveInteraction(interactionModel, language).catch(
      (error) =>
        error.json().then((body: { message?: string }) => ({
          success: false,
          status: error?.status,
          message: body?.message || 'Failed Request'
        }))
    )

    if (!response.success && response.status !== 503) {
      displayUserError({
        title: t('step.save.failure-title'),
        userMessageFallback: t('step.save.failure-message'),
        error: response
      })

      console.error(response.message)

      return false
    }

    const newUuidInteraction = response?.data?.new_interaction_ids?.[0]
    setUuidInteraction(newUuidInteraction)
    executeAutomationsAfterSuccess()
    onSave?.()
    setIsInteractionFinished(true)

    return true
  }

  const afterNextButton = async (step: number) => {
    const stepActivities = flatSteps[currentStep - 1]?.activity_models

    if (!stepActivities) {
      return
    }

    const { invalidActivities, updatedActivityValues } = await validateStep({
      activityModels: flatActivityModels,
      activityValues: flatActivityValues,
      stepActivities
    })

    dispatch(
      interactionModelOperations.updateActivities(
        flatValuesToOldActivities(updatedActivityValues)
      )
    )
    const currentInteractionModelItemGroup = steps[currentStep - 1]

    getStepErrorMessages({
      invalidActivities,
      stepName: currentInteractionModelItemGroup.des,
      t
    }).forEach((error) => showError(error))

    if (invalidActivities.length > 0) {
      return
    }

    if (currentInteractionModelItemGroup?.validation_group_callback) {
      const responseValidationCallback =
        await validateInteractionModelItemGroupCallback(
          currentInteractionModelItemGroup,
          currentInteractionModelItemGroup.validation_group_callback
        )
      if (!responseValidationCallback?.success) {
        displayUserError({ error: responseValidationCallback })
        return
      }
    }

    setCurrentStep(step)
  }

  const changeStep = (step: number) => {
    const finishingInteraction = step > totalSteps
    const isAnyStepWithValidation = step > currentStep

    // clicou no botão Salvar e Ativar
    if (finishingInteraction && interactionModel?.data) {
      saveAndFinish(interactionModel?.data)
    }

    // clicou no botão avançar
    // qualquer step, exceto finish e preview (sempre pelo botão next)
    if (!finishingInteraction && isAnyStepWithValidation) {
      afterNextButton(step)
    }

    // preview e também steps que estão voltando (botão previous)
    if (!finishingInteraction && !isAnyStepWithValidation) {
      setCurrentStep(step)
    }

    window.scrollTo(0, 0)
  }

  return {
    totalSteps,
    currentStep,
    currentStepData,
    uuidInteraction,
    setCurrentStep,
    setUuidInteraction,
    initialStepIndex,
    changeStep,
    isLoadedSteps,
    isInteractionFinished
  }
}

export default useStep
