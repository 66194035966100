import { feedbackcard } from '@yes.technology/react-toolkit'

import {
  FlatActivityModel,
  InteractionModelItemGroup,
  Translate
} from 'types/shared'

export const showError = (title: string, message?: string) => {
  if (!title) return false

  feedbackcard(title, {
    type: 'error',
    message
  })
}

export const getStepsToBeShown = (
  itemGroups: InteractionModelItemGroup[] = []
) => itemGroups.filter((itemGroup) => itemGroup?.show !== false)

type ShowStepErrorMessagesProps = {
  stepName: string
  invalidActivities: {
    helperText: string
    activityModel: FlatActivityModel
    parentPath?: (string | number)[]
  }[]
  t: Translate
}

export const getStepErrorMessages = ({
  stepName,
  invalidActivities,
  t
}: ShowStepErrorMessagesProps): string[] => {
  if (!invalidActivities.length) {
    return []
  }

  return [
    ...invalidActivities.flatMap((invalidActivity) => {
      if (invalidActivity.activityModel.content_type === 'agreement_term') {
        return invalidActivity.activityModel.error_text?.des || stepName
      }

      const hasInvalidFormat =
        invalidActivity.helperText === 'activity-input.validation.wrong_format'

      if (invalidActivity.parentPath?.length) {
        const groupIndex = parseInt(
          invalidActivity.parentPath.at(-1)!.toString()
        )

        return t(
          hasInvalidFormat
            ? 'step.validation.message-invalid-format-in-activity-group'
            : 'step.validation.message-is-required-field-in-activity-group',
          {
            field: invalidActivity.activityModel.display_text.des,
            groupNumber: String(groupIndex + 1)
          }
        )
      }

      return t(
        hasInvalidFormat
          ? 'step.validation.message-invalid-format'
          : 'step.validation.message-is-required-field',
        {
          field: invalidActivity.activityModel.display_text.des
        }
      )
    }),
    ...(invalidActivities.length > 1
      ? [t('step.validation.message-all-required-fields')]
      : [])
  ]
}
