import { SerializedError } from '@reduxjs/toolkit'
import createAppSlice from 'state/createAppSlice'
import {
  Activity,
  ActivityModel,
  InteractionModel,
  InteractionModelData,
  InteractionModelItemGroup
} from 'types/shared'
import api from 'utils/api'
import {
  addActivityGroup,
  removeActivityGroup,
  setFlatInteractionModelActivities,
  setInteractionModelActivities
} from 'utils/interactionModel'
import { interactionModelDataToFlat } from '../utils/interactionModelDataToFlat/interactionModelDataToFlat'

const requestPending = (state: InteractionModel) => ({
  ...state,
  isFetching: true,
  error: ''
})

const requestError = (
  state: InteractionModel,
  action: { error: SerializedError }
) => ({
  ...state,
  data: undefined,
  error: action.error.message || 'Unknown error',
  lastUpdated: Date.now(),
  isFetching: false
})

const formatInteractionModelData = (
  interactionModelData: InteractionModelData
) => {
  let newInteractionModel = interactionModelData

  const { blueprints = [] } = interactionModelData || {}

  blueprints.forEach((blueprint) => {
    if (blueprint.initial_values) {
      newInteractionModel = setInteractionModelActivities(
        interactionModelData,
        blueprint.initial_values
      )
    }
  })

  return newInteractionModel
}

const addOriginalUuidToActivityGroups = (
  interactionModelData: InteractionModelData
) => {
  interactionModelData.interaction_model_item_groups.forEach((group) => {
    group.activity_models.forEach((activityModel) => {
      if (
        activityModel.content_type === 'activity_group' &&
        activityModel.activity_models
      ) {
        const initialActivityGroupActivities =
          activityModel.activity_models[0].map((originalActivityModel) => ({
            ...originalActivityModel,
            uuid: `${originalActivityModel.uuid}-0`,
            originalUuid: originalActivityModel.uuid
          }))

        activityModel.activity_models = [initialActivityGroupActivities]
      }
    })
  })

  return interactionModelData
}

export const interactionModelInitialState: InteractionModel = {
  isFetching: false,
  data: undefined,
  flatData: undefined,
  error: '',
  geolocation: null,
  user: '',
  dateStartInteraction: '',
  language: '',
  workflowUuid: null,
  lastUpdated: 0,
  interactionFinished: false
}

export const {
  reducer: interactionModelReducer,
  actions: interactionModelActions
} = createAppSlice({
  name: 'InteractionModel',
  initialState: interactionModelInitialState,
  reducers: (create) => ({
    getInteractionModel: create.asyncThunk(
      (idInteractionModel: string) => {
        return api
          .getInteractionModels(idInteractionModel, false)
          .then((response) => {
            const formattedState = addOriginalUuidToActivityGroups(
              formatInteractionModelData(response?.data?.interaction_model)
            )

            return {
              data: formattedState,
              flatData: interactionModelDataToFlat(formattedState)
            }
          })
      },
      {
        pending: requestPending,
        rejected: requestError,
        fulfilled: (state, action) => ({
          ...state,
          data: action.payload.data,
          flatData: action.payload.flatData,
          isFetching: false,
          error: '',
          interactionFinished: true,
          lastUpdated: Date.now()
        })
      }
    ),

    removeActivityGroup: create.preparedReducer(
      (activityModelUuid: string, removedGroupIndex: number) => ({
        payload: { activityModelUuid, removedGroupIndex }
      }),
      (state, action) => {
        if (!state.data || !state.flatData) {
          console.error(
            'Called removeActivityGroup without an interaction model'
          )
          return
        }

        state.data = removeActivityGroup({
          interactionModel: state.data,
          activityModelUuid: action.payload.activityModelUuid,
          removedGroupIndex: action.payload.removedGroupIndex
        })

        const groups =
          state.flatData.activity_values[action.payload.activityModelUuid]

        if (Array.isArray(groups)) {
          groups.splice(action.payload.removedGroupIndex, 1)
        }
      }
    ),

    addActivityGroup: create.preparedReducer(
      (activityModelUuid: string, newActivityGroup: ActivityModel[]) => ({
        payload: { activityModelUuid, newActivityGroup }
      }),
      (state, action) => {
        if (!state.data || !state.flatData) {
          console.error('Called addActivityGroup without an interaction model')
          return
        }

        state.data = addActivityGroup({
          interactionModel: state.data,
          activityModelUuid: action.payload.activityModelUuid,
          newActivityGroup: action.payload.newActivityGroup
        })

        const groups =
          state.flatData.activity_values[action.payload.activityModelUuid]

        state.flatData.activity_values[action.payload.activityModelUuid] = [
          ...(Array.isArray(groups) ? groups : []),
          {}
        ]
      }
    ),

    updateActivities: create.preparedReducer(
      (activities: Activity[]) => ({ payload: { activities } }),
      (state, action) => {
        if (!state.data || !state.flatData) {
          console.error('Called updateActivities without an interaction model')
          return
        }

        state.data = setInteractionModelActivities(
          state.data,
          action.payload.activities
        )

        setFlatInteractionModelActivities(
          state.flatData,
          action.payload.activities
        )
      }
    ),

    updateItemGroups: create.preparedReducer(
      (itemGroups: InteractionModelItemGroup[]) => ({
        payload: { itemGroups }
      }),
      (state, action) => {
        if (!state.data || !state.flatData) {
          console.error('Called updateItemGroups without an interaction model')
          return
        }

        state.data.interaction_model_item_groups = action.payload.itemGroups

        state.flatData = interactionModelDataToFlat({
          ...state.flatData,
          interaction_model_item_groups: action.payload.itemGroups
        })
      }
    ),

    setGeolocation: create.preparedReducer(
      (coords: InteractionModel['geolocation']) => ({ payload: { coords } }),
      (state, action) => ({ ...state, geolocation: action.payload.coords })
    ),

    setInitialConfiguration: create.preparedReducer(
      (
        user: InteractionModel['user'],
        dateStartInteraction: InteractionModel['dateStartInteraction'],
        language: InteractionModel['language']
      ) => ({ payload: { user, dateStartInteraction, language } }),
      (state, action) => ({
        ...state,
        language: action.payload.language,
        dateStartInteraction: action.payload.dateStartInteraction,
        user: action.payload.user
      })
    ),

    setWorkflow: create.preparedReducer(
      (workflowUuid: InteractionModel['workflowUuid']) => ({
        payload: { workflowUuid }
      }),
      (state, action) => {
        if (state.workflowUuid !== action.payload.workflowUuid) {
          state.data = undefined
        }

        state.workflowUuid = action.payload.workflowUuid
      }
    ),

    setConfigToNewInteraction: create.preparedReducer(
      (dateStartInteraction: InteractionModel['dateStartInteraction']) => ({
        payload: { dateStartInteraction }
      }),
      (state, action) => ({
        ...state,
        dateStartInteraction: action.payload.dateStartInteraction,
        interactionFinished: false
      })
    )
  })
})
